<template>
  <div>
    <Cycles
      id="cycle"
      :first="locale[lang].PUISSANCE.FIRST"
      :images="images"
      :check="locale[lang].PUISSANCE.CHECK"
      :semaine="locale[lang].PUISSANCE.SEMAINE"
      :semaineImg="semaineImg"
      :apres="locale[lang].PUISSANCE.APRES"
      :exercices="locale[lang].PUISSANCE.EXERCICES"
      :infoShop="locale[lang].PUISSANCE.INFOSHOP"
    />
  </div>
</template>

<script>
import Cycles from "@/components/Cycles.vue";
export default {
  components: {
    Cycles,
  },
  data() {
    return {
      images: [
        "Cycles/FutPui grupo 190.png",
        "Cycles/FutPui grupo 191.png",
        "Cycles/FutPui grupo 192.png",
      ],
      semaineImg: "https://da32ev14kd4yl.cloudfront.net/versioned/preparationphysiquefootball/jeux-reduits-11%20(2).png",
    };
  },
};
</script>
